body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #FAFAFA;
  color: #264653;
}

.container {
  margin-top: 20px;
}

.horizontal-form .form-row {
  display: flex;
  align-items: center;
}

form div {
  margin-right: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #2A9D8F;
}

input, select {
  width: 150px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  color: #264653;
  border: 1px solid #2A9D8F;
  border-radius: 4px;
}

.add-row {
  padding: 10px;
  background-color: #F4A261;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 48px; /* Ensure the button is square */
}

.add-row:hover {
  background-color: #E76F51;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

th, td {
  border: 1px solid #3E4B59;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #F4A261;
  color: white;
}

thead {
  background-color: #F4A261;
  color: white;
}
